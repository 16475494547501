/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
.cinnux {
  @import "defaultBody";
  --primary-color: #f26b18;
  --primary-lighter-color: #f5ac81;
  --primary-darker-color: #bd5515;
  //--text-primary-color: #{$light-primary-text};
  //--text-primary-lighter-color: #{$dark-primary-text};
  //--text-primary-darker-color: #{$light-primary-text};
  --accent-color: #759fbc;
  --accent-lighter-color: #d6e2eb;
  --accent-darker-color: #5883a5;
  //--text-accent-color: #{$dark-primary-text};
  //--text-accent-lighter-color: #{$dark-primary-text};
  //--text-accent-darker-color: #{$light-primary-text};
  --font: "Source Sans Pro", "Helvetica Neue", sans-serif;
  --font-2: "Montserrat";
  --font-3: "Roboto";
  .mat-toolbar {
    background: #fff;
    box-shadow: none !important;
    border-bottom: 1px solid #ccc;
  }
  @import "themeGen";
}
