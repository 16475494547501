/**
 * The main file to create the font specifications
 *
 * @class SourceSansPro.scss
 */
@import 'mixins/string';
@import 'config';
@import 'definitions';

@each $type in $font-types {
  $index: index($font-types, $type);
  @font-face {
    font-family: $font-name;
    font-style: normal;
    font-weight: nth($font-weights, $index);
    src: local($font-name + ' ' + $type), local(trim($font-name) + '-' + $type),
      url($font-folder + trim($font-name) + '-' + $type + '.' + $font-type)
        format($font-type);
  }
  @font-face {
    font-family: $font-name + '-' + $type;
    font-style: normal;
    src: local($font-name + ' ' + $type), local(trim($font-name) + '-' + $type),
      url($font-folder + trim($font-name) + '-' + $type + '.' + $font-type)
        format($font-type);
  }
}
