.opgelucht {
  // import defaultBody.scss
  @import "defaultBody";

  --primary-color: #00333d;
  --primary-lighter-color: #026579;
  --primary-darker-color: #000;
  //--text-primary-color: #{$light-primary-text};
  //--text-primary-lighter-color: #{$dark-primary-text};
  //--text-primary-darker-color: #{$light-primary-text};
  --accent-color: #759fbc;
  --accent-lighter-color: #d6e2eb;
  --accent-darker-color: #5883a5;
  //--text-accent-color: #{$dark-primary-text};
  //--text-accent-lighter-color: #{$dark-primary-text};
  //--text-accent-darker-color: #{$light-primary-text};
  --font: "Sora";
  --font-2: "Sora";
  --font-3: "Sora";

  .mat-toolbar {
    background: #fff;
    box-shadow: none !important;
    border-bottom: 1px solid #ccc;
  }

  @import "themeGen";
}
