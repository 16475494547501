:root {
  --primary-color: #a32b37;
  --primary-lighter-color: #e3bfc3;
  --primary-darker-color: #881a23;
  --text-primary-color: #{white};
  --text-primary-lighter-color: #{rgba(black, 0.87)};
  --text-primary-darker-color: #{white};
  --accent-color: #759fbc;
  --accent-lighter-color: #d6e2eb;
  --accent-darker-color: #5883a5;
  --text-accent-color: #{rgba(black, 0.87)};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{white};
  --warn-color: #ff0303;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0202;
  --text-warn-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --text-warn-darker-color: #{white};
}
