@use "@angular/material" as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}
h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}
h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}
h4 {
  display: block;
  font-size: 1em;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}
// Plus imports for other components in your app.
//
//// Include the common styles for Angular Material. We include this here so that you only
//// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!
//// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
////  The following line adds:
////    1. Default typography styles for all components
////    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
////  If you specify typography styles for the components you use elsewhere, you should delete this line.
////  If you don't need the default component typographies but still want the hierarchy styles,
////  you can delete this line and instead use:
////    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
//@include mat.all-legacy-component-typographies();
//@include mat.legacy-core();

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/RobotoFlex-VariableFont.ttf") format("truetype");
}
@font-face {
  font-family: "Sora";
  src: url("/assets/fonts/Sora-VariableFont_wght.ttf") format("truetype");
}

@import "scss/SourceSansPro";
@import "scss/Montserrat";
@import "scss/MaterialIcons";

@import "theme";

@import "themes/cinnux";
@import "themes/corrente";
@import "themes/opgelucht";
@import "@ctrl/ngx-emoji-mart/picker";

.text-color {
  color: var(--primary-color);
}

.mat-raised-button.mat-primary {
  background-color: var(--primary-color) !important;
}

body {
  margin: 0;
  font-size: 12px;
  font-family: var(--font);
}

.full-width {
  width: 100%;
}

.container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.spinner {
  left: 49%;
  position: fixed !important;
  top: 15%;
  z-index: 9999;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.login-container {
  height: 80vh;
}

.login-actions {
  margin-left: unset !important;
}

.mat-list-item,
.mat-menu-item {
  //font-size: 12px !important;
}

.command-wrapper button {
  margin-left: 10px;
  margin-bottom: 5px;
}

.mat-dialog-title {
  margin: 0 !important;
}

.mat-column-actions {
  flex: 0 0 60px;
}

.mat-table .mat-row:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.mat-table td.mat-cell a:hover {
  text-decoration: underline;
}

.mat-table td.mat-cell:first-child,
.mat-table td.mat-footer-cell:first-child,
.mat-table th.mat-header-cell:first-child {
  padding-left: 20px !important;
}

.mat-table td.mat-cell:last-child,
.mat-table td.mat-footer-cell:last-child,
.mat-table th.mat-header-cell:last-child {
  padding-right: 10px !important;
}

.mat-table .mat-cell a {
  color: inherit;
  text-decoration: none;
}

.no-records {
  padding-top: 20px;
  padding-bottom: 20px;
}

.refresh-button {
  margin-top: 8px !important;
}

.no-records .mat-icon {
  width: 130px;
  height: 130px;
  font-size: 130px;
  color: #c7c7c7;
}

p .mat-icon {
  vertical-align: middle;
}

.mat-slide-toggle.full-width {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mat-tooltip {
  font-size: 14px;
}

.fixed-fab {
  position: fixed !important;
  right: 2%;
  bottom: 3%;
}

/* Highlight on hover */
.submitted-timesheet-day:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
/* Highlight */
.submitted-timesheet-day>div:not(.mat-calendar-body-selected) {
  border-radius: 999px;
  background-color: rgba(14, 152, 5, 0.25);
}

.table-wrapper {
  overflow: auto;
  height: calc(100vh - 270px);
  max-height: 450px;
  /* padding: 10px 5px; */
}

.animate {
  animation-name: show;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.mat-button-toggle-label-content {
  line-height: 0px !important;
  padding: 12px 10px 10px 10px !important;
}
mat-button-toggle-group {
  margin: 0;
}
mat-button-toggle {
  border: 0 !important;
  font-family: var(--font-2);
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b2828;
  &.mat-button-toggle-checked.button-available {
    background-color: #c8d6af;
  }
  &.mat-button-toggle-checked.button-unavailable {
    background-color: #c17142;
    color: #fff;
  }
}

.ngx-timepicker--disabled {
  opacity: 0.4;
}

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
.mat-dialog-container {
  max-height: 90vh !important;
}
quill-editor[disabled] {
  .ql-toolbar,
  .ql-container {
    opacity: 0.5;
    cursor: not-allowed;
    * {
      cursor: not-allowed;
    }
  }
}
quill-editor {
  .ql-container {
    min-height: 100px;
  }
}
