/**
 * The basic configuration of the Font
 *
 * @class _config.scss
 */

/*
 * Add the light fonts
 *
 * @property use-light
 * @type Boolean
 * @default true
 */
$use-light: true;

/*
 * Add the regular fonts
 *
 * @property use-regular
 * @type Boolean
 * @default true
 */
$use-regular: true;

/*
 * Add the semi-bolded fonts
 *
 * @property use-semibold
 * @type Boolean
 * @default true
 */
$use-semibold: true;

/*
 * The directory where the fonts are available
 *
 * @property use-light
 * @type String
 * @default "../fonts/"
 */
$font-folder: '../assets/fonts/SourceSansPro/';

/*
 * The name of the font
 *
 * @property font-name
 * @type String
 * @default "Source Sans Pro"
 */
$font-name: 'SourceSansPro';

/*
 * The extension of the given font
 *
 * @property font-type
 * @type String
 * @default "woff2"
 */
$font-type: 'woff2';
