$MontserratPath: "./assets/fonts/Montserrat/" !default;

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf");
  //src: url('./assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  //url('./assets/fonts/Montserrat/Regular/Montserrat-Regular.woff') format('woff'),
  //url('./assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf') format('truetype'),
  //url('./assets/fonts/Montserrat/Regular/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf");
  //src: url('./assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  //url('./assets/fonts/Montserrat/Bold/Montserrat-Bold.woff') format('woff'),
  //url('./assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf') format('truetype'),
  //url('./assets/fonts/Montserrat/Bold/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Montserrat/Medium/Montserrat-Medium.ttf");
  //src: url('./assets/fonts/Montserrat/Medium/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
  //url('./assets/fonts/Montserrat/Medium/Montserrat-Medium.woff') format('woff'),
  //url('./assets/fonts/Montserrat/Medium/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/Montserrat/Light/Montserrat-Light.ttf");
  //src: url('./assets/fonts/Montserrat/Light/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
  //url('./assets/fonts/Montserrat/Light/Montserrat-Light.woff') format('woff'),
  //url('./assets/fonts/Montserrat/Light/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf");
  //src: url('./assets/fonts/Montserrat/Regular/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  //url('./assets/fonts/Montserrat/Regular/Montserrat-Regular.woff') format('woff'),
  //url('./assets/fonts/Montserrat/Regular/Montserrat-Regular.ttf') format('truetype'),
  //url('./assets/fonts/Montserrat/Regular/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
}

@font-face {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf");
  //src: url('./assets/fonts/Montserrat/Bold/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  //url('./assets/fonts/Montserrat/Bold/Montserrat-Bold.woff') format('woff'),
  //url('./assets/fonts/Montserrat/Bold/Montserrat-Bold.ttf') format('truetype'),
  //url('./assets/fonts/Montserrat/Bold/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
}

@font-face {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Montserrat/Medium/Montserrat-Medium.ttf");
  //src: url('./assets/fonts/Montserrat/Medium/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
  //url('./assets/fonts/Montserrat/Medium/Montserrat-Medium.woff') format('woff'),
  //url('./assets/fonts/Montserrat/Medium/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: "Montserrat-Light";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Montserrat/Light/Montserrat-Light.ttf");
  //src: url('./assets/fonts/Montserrat/Light/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
  //url('./assets/fonts/Montserrat/Light/Montserrat-Light.woff') format('woff'),
  //url('./assets/fonts/Montserrat/Light/Montserrat-Light.ttf') format('truetype');
}
