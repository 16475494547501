.corrente {
  @import "defaultBody";
  --primary-color: #00719e;
  --primary-lighter-color: #0094ce;
  --primary-darker-color: #02415a;
  //--text-primary-color: #{$light-primary-text};
  //--text-primary-lighter-color: #{$dark-primary-text};
  //--text-primary-darker-color: #{$light-primary-text};
  --accent-color: #f1b043;
  --accent-lighter-color: #e6c180;
  --accent-darker-color: #af802f;
  //--text-accent-color: #{$dark-primary-text};
  //--text-accent-lighter-color: #{$dark-primary-text};
  //--text-accent-darker-color: #{$light-primary-text};
  --font: "Source Sans Pro", "Helvetica Neue", sans-serif;
  --font-2: "Montserrat";
  --font-3: "Roboto";

  @import "themeGen";
}
