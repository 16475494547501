/**
 * The basic definitions of the Font
 *
 * @class _definitions.scss
 */

/*
 * Defines the current font types 
 *
 * @property $font-types
 * @type {Map}
 * @default (Light, Regular, SemiBold)
 */
$font-types: (ExtraLight, Light, Regular, SemiBold, Bold, Black);

/*
 * Defines the current font weights
 *
 * @property $font-weights
 * @type {Map}
 * @default (300, 400, 600)
 */
$font-weights: 200, 300, 400, 600, 700, 800;

/*
 * Defines the names of the Unicode character set
 *
 * @property $font-characters
 * @type {Map}
 * @default (cyrillic-ext, cyrillic, greek-ext, greek, vietnamese, latin-ext, latin)
 */
$font-characters: (
  cyrillic-ext,
  cyrillic,
  greek-ext,
  greek,
  vietnamese,
  latin-ext,
  latin
);

/*
 * Defines the values of the Unicode character set
 *
 * @property $font-characters-map
 * @type {Map}
 * @default ("U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", "U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", "U+1F00-1FFF", "U+0370-03FF", "U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB", "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;")
 */
$font-characters-map: (
  'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
  'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  'U+1F00-1FFF',
  'U+0370-03FF',
  'U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB',
  'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
  'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;'
);
